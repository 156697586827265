var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",attrs:{"id":"containerSelect"}},[_c('div',{staticClass:"DuoContainer"},[_c('div',{staticClass:"multiselec",attrs:{"id":"categoria"}},[_c('label',{staticClass:"labelText"},[_vm._v("Categoria")]),_c('multiselect',{attrs:{"options":_vm.categoriaOptions,"multiple":true,"loading":_vm.loadingCategoria,"close-on-select":false,"clear-on-select":false,"show-labels":false,"searchable":false,"maxHeight":150,"limit":1,"limit-text":_vm.limitText,"placeholder":"Escolha a Categoria","label":"name","track-by":"name"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(_vm.valorCategoria.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.valorCategoria.length > 1 ? ((_vm.valorCategoria.length) + " selecionados") : ((_vm.valorCategoria.length) + " selecionado"))+" ")]):_vm._e()]}}]),model:{value:(_vm.valorCategoria),callback:function ($$v) {_vm.valorCategoria=$$v},expression:"valorCategoria"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Lista vazia")])])],1),_c('div',{staticClass:"multiselec",attrs:{"id":"pasta"}},[_c('label',{staticClass:"labelText"},[_vm._v("Pasta Interna")]),_c('multiselect',{attrs:{"options":_vm.pastaOptions,"multiple":true,"close-on-select":false,"clear-on-select":false,"show-labels":false,"searchable":false,"maxHeight":150,"loading":_vm.loadingPastaInterna,"limit":1,"limit-text":_vm.limitText,"openDirection":"bottom","placeholder":"Escolha a Pasta Interna","label":"name","track-by":"name"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(_vm.valorPastaInterna.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.valorPastaInterna.length > 1 ? ((_vm.valorPastaInterna.length) + " selecionados") : ((_vm.valorPastaInterna.length) + " selecionado"))+" ")]):_vm._e()]}}]),model:{value:(_vm.valorPastaInterna),callback:function ($$v) {_vm.valorPastaInterna=$$v},expression:"valorPastaInterna"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Lista vazia")])])],1)]),_c('div',{staticClass:"multiselec",attrs:{"id":"Subcategoria"}},[_c('label',{staticClass:"labelText"},[_vm._v("SubCategoria")]),_c('multiselect',{attrs:{"options":_vm.subcategoriaOptions,"multiple":true,"close-on-select":false,"clear-on-select":false,"show-labels":false,"searchable":false,"maxHeight":150,"loading":_vm.loadingSubCategoria,"limit":1,"limit-text":_vm.limitText,"openDirection":"top","placeholder":"Escolha a SubCategoria","label":"name","track-by":"name"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(_vm.valorSubCategoria.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(_vm.valorSubCategoria.length > 1 ? ((_vm.valorSubCategoria.length) + " selecionados") : ((_vm.valorSubCategoria.length) + " selecionado"))+" ")]):_vm._e()]}}]),model:{value:(_vm.valorSubCategoria),callback:function ($$v) {_vm.valorSubCategoria=$$v},expression:"valorSubCategoria"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Lista vazia")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }